










































import Vue from "vue"
import DataInput from "@/components/DataInput.vue" // @ is an alias to /src
import { Vulnerability } from "@/types"
import DataTable from "@/components/DataTable.vue"
import { Component, Prop } from "vue-property-decorator"

@Component({
  components: {
    DataTable,
    DataInput,
  },
})
export default class Home extends Vue {
  @Prop() private presetUrl?: string
  private selectedVulnerabilities: Vulnerability[] = []
  private reportLoaded = false

  private reactivelySetNewVulnerabilities(newVulnerabilities: Vulnerability[]) {
    this.selectedVulnerabilities.splice(0)
    newVulnerabilities.forEach(
      (item: Vulnerability, index: number) => (item.id = index)
    )
    this.selectedVulnerabilities.push(...newVulnerabilities)
    this.reportLoaded = true
  }
}
