












































































import Vue from "vue"
import VueFileAgent from "vue-file-agent"
import { Component } from "vue-property-decorator"
import { Fragment } from "vue-frag"
// Define the props by using Vue's canonical way.
const ReportUrlFetcherProps = Vue.extend({
  props: {
    onNewReport: Function,
    presetUrl: String,
  },
})
Vue.use(VueFileAgent)
@Component({
  components: { Fragment },
})
export default class ReportUrlFetcher extends ReportUrlFetcherProps {
  private url = ""
  private state = "ready"
  private dialog = false
  private headerName = ""
  private headerValue = ""
  mounted(): void {
    this.loadAuthorization()
    if (this.presetUrl) {
      this.url = this.presetUrl
      this.fetchReportFromUrl()
    }
  }
  public loadAuthorization(): void {
    this.headerName = localStorage.getItem("headerName") || ""
    this.headerValue = localStorage.getItem("headerValue") || ""
  }
  public saveAuthorization(): void {
    localStorage.setItem("headerName", this.headerName)
    localStorage.setItem("headerValue", this.headerValue)
    this.dialog = false
  }

  public async fetchReportFromUrl(): Promise<void> {
    if (this.url) {
      this.state = "loading"
      const headers: Record<string, string> = {}
      if (this.headerName && this.headerValue) {
        headers[this.headerName] = this.headerValue
      }
      try {
        const response = await fetch(this.url, { headers })
        this.state = "ready"
        if (response.ok) {
          const report = await response.json()
          if (!report) {
            throw new Error("Response from URL doesn't look like JSON")
          }
          this.onNewReport(report)
          return
        }
      } catch (error) {
        console.error(error)
      }
      this.state = "error"
    }
  }
}
