


































































import Vue from "vue"
import { Component, Prop } from "vue-property-decorator"
import { Vulnerability, VulnerabilitySeverityInformation } from "@/types"
import Clipboard from "v-clipboard"

Vue.use(Clipboard)

type VulnerabilityStats = {
  total: number
  fixable: number
  severityInformation: VulnerabilitySeverityInformation[]
}
@Component
export default class DataTable extends Vue {
  @Prop({ required: true }) selectedVulnerabilities: Vulnerability[]
  private ignoredVulnerabilities: Vulnerability[] = []
  private expanded: unknown[] = []
  private sortBy = "Severity"
  private search = ""

  get ignoredCves(): string {
    const uniqueCves = Array.from(
      new Set(this.ignoredVulnerabilities.map((v) => v.VulnerabilityID))
    ).map((id) => {
      return this.ignoredVulnerabilities.find((v) => v.VulnerabilityID === id)
    })
    const resultingLines: string[] = []
    uniqueCves.sort((a, b) => this.compareBySeverity(a.Severity, b.Severity))

    uniqueCves?.forEach((v) => {
      resultingLines.push(`# ${v.Severity}: ${v.Title}`)
      resultingLines.push(v.VulnerabilityID)
    })
    return resultingLines.join("\n")
  }

  get vulnerabilityStats(): VulnerabilityStats {
    const total = this.selectedVulnerabilities.length
    const fixable = this.selectedVulnerabilities.filter(
      (vulnerabilities) => vulnerabilities.FixedVersion != undefined
    ).length

    const vulnerabilitySeverityTypes: string[] = [
      ...new Set(this.selectedVulnerabilities.map((item) => item.Severity)),
    ]

    const severityInformation = vulnerabilitySeverityTypes
      .map((severity) => {
        return {
          severity,
          count: this.selectedVulnerabilities.filter(
            (vulnerabilities) => vulnerabilities.Severity === severity
          ).length,
        }
      })
      .sort((a, b) => this.compareBySeverity(a.severity, b.severity))
    return {
      total,
      fixable,
      severityInformation,
    }
  }

  private severitySortOrder: Record<string, number> = {
    CRITICAL: 0,
    HIGH: 1,
    MEDIUM: 2,
    LOW: 3,
    UNKNOWN: 4,
  }

  private compareBySeverity(a: string, b: string): number {
    return this.severitySortOrder[a] - this.severitySortOrder[b]
  }

  private headers = [
    { text: "Target", value: "Target" },
    { text: "PkgName", value: "PkgName" },
    { text: "VulnerabilityID", value: "VulnerabilityID" },
    {
      value: "Severity",
      text: "Severity",
      sort: this.compareBySeverity,
    },
    { text: "InstalledVersion", value: "InstalledVersion" },
    { text: "FixedVersion", value: "FixedVersion" },
  ]
}
